<script lang="ts" setup="">
  import CarouselSlider from '~/components/sliders/carousel/CarouselSlider.vue';
  import { getPremiers } from '~/services/modules/movies.service';
  import CarouselSkeleton from '~/components/sliders/carousel/CarouselSkeleton.vue';
  import { maxMovieCardsCountAtSlider } from '~/consts/slider';

  const { data: premiers, isFetching, suspense } = getPremiers();
  onServerPrefetch(async () => await suspense());

  const { isTabletLarge } = useScreen();
</script>

<template>
  <v-wrapper
    v-if="premiers?.length"
    class="premiers full-width"
    title="Мировые премьеры"
    :link="{ name: 'Смотреть все', route: { name: 'premiers' } }"
    section
  >
    <carousel-slider
      v-if="premiers"
      :class="{ 'content-right': !isTabletLarge }"
      :items="premiers?.slice(0, maxMovieCardsCountAtSlider) ?? []"
    />
    <carousel-skeleton v-else />
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .premiers {
    padding: 56px 0;
    position: relative;

    .carousel-slider {
      padding-bottom: 24px;
    }

    @include gradientBackground(-100px, 0, $new: true);
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
